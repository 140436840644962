import * as React from 'react';
import '../styles/App.less';
import {Loading} from '../components/Loading/Loading';
import {Helmet} from 'react-helmet';

// markup
const IndexPage = (props: any) => {
  return (
    <>
      <Helmet htmlAttributes={{lang: 'en'}}>
        <meta charSet='utf-8' />
        <title>Cribl.Cloud - Loading</title>
        <link rel='canonical' href='https://portal.cribl.cloud/loading' />
      </Helmet>
      <main key={'loading'} className={'main-holder'}>
        <Loading {...props} />
      </main>
    </>
  );
};

export default IndexPage;
