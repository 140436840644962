import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import {BrowserView, MobileView} from 'react-device-detect';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {navigate, PageProps} from 'gatsby';
import {Button, Layout, message, Modal, Result, Typography} from 'antd';
import './styles.less';
import {useAuth} from '../Auth/AuthContext';
import {login, postLoginHook, postOrganization} from '../../services/API';
import {SideComponent} from '../SideComponent/SideComponent';

const {Content} = Layout;
const {Title, Paragraph} = Typography;

interface ILoadingState {
  password?: string;
  emailAddress?: string;
}

export const Loading: FC<PageProps<any, any, ILoadingState>> = (props) => {
  const {cloudOrganizationsCreateWithRegionUi} = useFlags();
  const [visible, setVisible] = useState(false);
  const {emailAddress, password} = props?.location?.state ?? {};
  const errorMsg = `We were unable to create your cloud environment please contact support@cribl.io or try again later.`;
  const {authToken} = useAuth();

  const postLogin = async () => {
    try {
      await login({emailAddress, password, isSignUpFlow: true});
      return true;
    } catch (e) {
      message.error(errorMsg);
      await navigate('/');
      return false;
    }
  };

  const setup = async () => {
    const isLoginSuccess = await postLogin();
    if (isLoginSuccess) {
      if (cloudOrganizationsCreateWithRegionUi) {
        return await navigate('/organizations', {
          state: {isSignUpFlow: true}
        });
      }
      try {
        const response = await postOrganization({token: authToken});
        await postLoginHook(response);
      } catch (err) {
        message.error(err.message || errorMsg);
        return await navigate('/organizations', {
          state: {isSignUpFlow: true}
        });
      }
    }
  };

  useEffect(() => {
    setup();
  }, []);

  return (
    <Layout>
      <BrowserView renderWithFragment={true}>
        <SideComponent />
      </BrowserView>
      <Content>
        <Result
          status='success'
          title='Successfully Signed Up For Cribl Cloud!'
          subTitle="We've sent highly trained goats to prepare your cloud environment"
          style={{background: 'transparent', color: '#fff !important'}}
          extra={[
            <Title level={3} key={1}>
              Cloud server configuration can take a few minutes
            </Title>,
            <Title level={5} key={2}>
              You can wait here to be redirect once completed, or you can leave and we will send you an email when it's
              setup.
            </Title>,
            <Paragraph key={3}>In the meantime...</Paragraph>,
            <Button className={'orange-button'} type='primary' key='console' onClick={() => setVisible(true)}>
              Protect The Pipeline
            </Button>
          ]}
        />
      </Content>
      <MobileView renderWithFragment={true}>
        <SideComponent />
      </MobileView>
      <Modal
        style={{padding: 0, backgroundColor: 'black'}}
        centered
        visible={visible}
        width={1000}
        okButtonProps={{style: {visibility: 'hidden'}}}
        cancelButtonProps={{style: {visibility: 'hidden'}}}
        onCancel={() => setVisible(false)}
      >
        <iframe style={{paddingTop: '50px'}} src={'https://cdn.cribl.io/cyoa/index.html'} width={1000} height={700} />
      </Modal>
    </Layout>
  );
};
